import { gql } from '~/__gql-generated__';
import { Link } from '@solidjs/meta';
import { cache, createAsync, type RouteDefinition } from '@solidjs/router';
import { For, Show } from 'solid-js';

import Ad from '~/components/Ad';
import PlayerHome from '~/components/player-home';
import Poll from '~/components/Poll';
import PostLinkItem from '~/components/PostLinkItem';
import rootStyles from '~/components/Root.module.scss';
import SmartA from '~/components/SmartA';
import Title from '~/components/Title';
import TopSongs from '~/components/TopSongs';
import TopTags from '~/components/TopTags';
import styles from '~/routes/index.module.scss';
import { fragmentToJsx } from '~/utils/fragmentToJsx';
import { client } from '~/utils/graphql';
import { renderFragment } from '~/utils/renderFragment';
import { toYearMonthPair } from '~/utils/toYearMonthPair';
import { generateWidthThumbs } from '~/utils/generateThumbs';

const MAIN_PAGE = gql(`
  query MainPage {
    posts(
      where: { status: PUBLISHED, type: ARTICLE }
      options: { limit: 10, sort: [{ sticky: DESC }, { publishedAt: DESC }] }
    ) {
      id
      slug
      author {
        displayName
        profile {
          slug
        }
      }
      revisionsConnection(where: { edge: { status: CURRENT } }) {
        edges {
          node {
            title
            lead
            sticky
            publishedAt
            cover {
              url
            }
            category {
              id
              title
              slug
            }
          }
        }
      }
    }
  }
`);

const getMainPage = cache(async () => {
  'use server';

  const { data } = await client.query({ query: MAIN_PAGE });

  return {
    posts: data.posts.map(post => {
      const revision = post.revisionsConnection.edges[0].node;

      return {
        slug: post.slug,
        title: renderFragment(JSON.parse(revision.title ?? '{}'), true),
        lead: renderFragment(JSON.parse(revision.lead ?? '{}'), true),
        sticky: revision.sticky,
        publishedAt: new Date(revision.publishedAt),
        category: revision.category,
        author: post.author,
        cover: revision.cover?.url ?? '',
      };
    }),
  };
}, 'homepage');

export const route = {
  preload: () => getMainPage(),
} satisfies RouteDefinition;

export default function Home() {
  const data = createAsync(() => getMainPage(), {
    initialValue: { posts: [] },
  });

  const lcp = () => data().posts[0];

  return (
    <main>
      <Title />
      <Show when={lcp()}>
        <Link
          rel="preload"
          fetchpriority="high"
          as="image"
          href={lcp().cover}
          imagesrcset={generateWidthThumbs(lcp().cover, 16 / 9)}
          imagesizes="auto"
        />
      </Show>
      <TopTags />
      <div classList={{ [rootStyles.container]: true, [styles.main]: true }}>
        <div>
          <Ad slot="home1" />
          <PlayerHome />
          <Ad slot="home2" />
        </div>
        <TopSongs />
      </div>
      <div class={rootStyles.container}>
        <section>
          <h2 class={rootStyles['sr-only']}>Destaques</h2>
          <ul
            classList={{
              [rootStyles['clean-list']]: true,
              [styles.recent]: true,
            }}
          >
            <For each={data().posts.slice(0, 3)}>
              {post => (
                <li>
                  <Show when={post.sticky}>
                    <div class={styles.sticky}>
                      <IconTablerStarFilled />
                      <span class={rootStyles['sr-only']}>Artigo fixo</span>
                    </div>
                  </Show>
                  <SmartA
                    class={styles['post-link']}
                    href={`/${post.category?.slug ?? ''}/${toYearMonthPair(post.publishedAt)}/${post.slug ?? ''}`}
                  >
                    <img
                      src={post.cover}
                      srcSet={generateWidthThumbs(post.cover, 16 / 9)}
                      alt=""
                      loading="lazy"
                      sizes="auto"
                    />
                    <h3>
                      <span>{fragmentToJsx(post.title)}</span>
                    </h3>
                  </SmartA>
                  <div class={styles.meta}>
                    <SmartA href={`/${post.category?.slug ?? ''}`}>
                      {post.category?.title ?? ''}
                    </SmartA>{' '}
                    ·{' '}
                    <SmartA href={`/${post.author.profile?.slug ?? ''}`}>
                      {post.author.displayName}
                    </SmartA>
                  </div>
                </li>
              )}
            </For>
          </ul>
        </section>
        <Ad slot="home3" />
        <div class={styles.rest}>
          <section class={styles.archive}>
            <h2>Últimos Artigos</h2>
            <ul class={rootStyles['clean-list']}>
              <For each={data().posts.slice(3)}>
                {post => (
                  <li>
                    <PostLinkItem
                      title={post.title}
                      lead={post.lead}
                      slug={post.slug ?? ''}
                      sticky={post.sticky}
                      categoryTitle={post.category?.title ?? ''}
                      categorySlug={post.category?.slug ?? ''}
                      publishedAt={post.publishedAt}
                      cover={post.cover}
                      level="h3"
                    />
                  </li>
                )}
              </For>
            </ul>
          </section>
          <Poll />
          <Ad slot="home4" class={styles.ad} />
        </div>
        <Ad slot="home5" />
      </div>
    </main>
  );
}
