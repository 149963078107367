import { cookieStorage, makePersisted } from '@solid-primitives/storage';
import { action, cache, createAsync } from '@solidjs/router';
import { createSignal, For, Show } from 'solid-js';

import { gql } from '~/__gql-generated__';
import styles from '~/components/Poll.module.scss';
import rootStyles from '~/components/Root.module.scss';
import SmartA from '~/components/SmartA';
import { client } from '~/utils/graphql';
import SkeletonPlaceholder from './SkeletonPlaceholder';

const CURRENT_POLL = gql(`
  query CurrentPoll {
    polls(
      where: { isOpen: true }
      options: { sort: { createdAt: DESC }, limit: 1 }
    ) {
      id
      title
      options(options: { sort: { weight: ASC } }) {
        id
        title
        votes
      }
      optionsAggregate {
        node {
          votes {
            sum
          }
        }
      }
    }
  }
`);

const getCurrentPoll = cache(async () => {
  'use server';

  const { data } = await client.query({ query: CURRENT_POLL });
  return data.polls[0];
}, 'poll');

const VOTE_ON_POLL = gql(`
  mutation VoteOnPoll($pollId: ID!, $optionId: ID!) {
    voteOnPoll(pollId: $pollId, optionId: $optionId)
  }
`);

const [isVoting, setIsVoting] = createSignal(false);
const poll = createSignal('');

const [votedOnPoll, setVotedOnPoll] = makePersisted(poll, {
  name: 'votedOnPoll',
  storage: cookieStorage.withOptions({
    expires: new Date(Date.now() + 3e10),
    secure: true,
  }),
});

const voteOnPoll = action(async (data: FormData) => {
  try {
    setIsVoting(true);
    await client.mutate({
      mutation: VOTE_ON_POLL,
      variables: {
        pollId: data.get('pollId') as string,
        optionId: data.get('optionId') as string,
      },
    });
    setVotedOnPoll(data.get('pollId') as string);
  } catch (error: unknown) {
    console.error(error);
  } finally {
    setIsVoting(false);
  }
}, 'pollVote');

export default function Poll() {
  const poll = createAsync(() => getCurrentPoll());
  const totalVotes = () => poll()?.optionsAggregate?.node?.votes.sum ?? 0;

  const formatVotes = (votes: number, toCss = false) => {
    const percent = totalVotes() > 0 ? votes / totalVotes() : 0;
    if (toCss) {
      return `${(percent * 100).toString()}%`;
    }
    return percent.toLocaleString(import.meta.env.VITE_LOCALE, {
      style: 'percent',
      minimumFractionDigits: 2,
    });
  };

  return (
    <section classList={{ [styles.wrapper]: true, [rootStyles.section]: true }}>
      <h2>
        Enquete
        <span>
          <IconTablerChartBar />
        </span>
      </h2>
      <Show
        when={poll() && !isVoting()}
        fallback={
          <>
            <h3>
              <SkeletonPlaceholder inline size="100%" />
              <SkeletonPlaceholder inline size="30%" />
            </h3>
            <SkeletonPlaceholder class={styles.placeholder} />
            <SkeletonPlaceholder class={styles.placeholder} />
            <SkeletonPlaceholder class={styles.placeholder} />
            <SkeletonPlaceholder class={styles.placeholder} />
            <p>
              <SkeletonPlaceholder class={styles.button}>
                Votar
              </SkeletonPlaceholder>
              <SkeletonPlaceholder inline>Outras Enquetes</SkeletonPlaceholder>
            </p>
          </>
        }
      >
        <Show
          when={votedOnPoll() === poll()?.id}
          fallback={
            <form action={voteOnPoll} method="post">
              <input type="hidden" name="pollId" value={poll()?.id} />
              <h3>{poll()?.title}</h3>
              <ul class={styles.options}>
                <For each={poll()?.options}>
                  {option => (
                    <li>
                      <div>
                        <input
                          required
                          type="radio"
                          name="optionId"
                          id={`option_${option.id}`}
                          value={option.id}
                        />
                        <label for={`option_${option.id}`}>
                          {option.title}
                        </label>
                      </div>
                    </li>
                  )}
                </For>
              </ul>
              <p>
                <button type="submit">Votar</button>
                <SmartA href="/enquetes">Outras Enquetes</SmartA>
              </p>
            </form>
          }
        >
          <h3>{poll()?.title}</h3>
          <ul classList={{ [styles.options]: true, [styles.results]: true }}>
            <For each={poll()?.options}>
              {option => (
                <li style={{ '--percent': formatVotes(option.votes, true) }}>
                  {option.title}
                  <span class={styles.percent}>
                    {formatVotes(option.votes)}
                  </span>
                </li>
              )}
            </For>
          </ul>
          <p>
            {totalVotes().toLocaleString(import.meta.env.VITE_LOCALE)}{' '}
            {totalVotes() >= 2 ? 'votos' : 'voto'}
            <SmartA href="/enquetes">Outras Enquetes</SmartA>
          </p>
        </Show>
      </Show>
    </section>
  );
}
