import { cache, createAsync } from '@solidjs/router';
import { createEffect, createSignal, For, Show } from 'solid-js';
import { isServer } from 'solid-js/web';

import { gql } from '~/__gql-generated__';
import Image from '~/components/player/Image';
import rootStyles from '~/components/Root.module.scss';
import styles from '~/components/TopSongs.module.scss';
import { buildSources } from '~/utils/buildSources';
import { client } from '~/utils/graphql';

const labels = {
  playing: 'Pause',
  paused: 'Play',
  buffering: 'Carregando...',
};

const formatDelta = (value?: number) => {
  const absoluteValue = Math.abs(value ?? 0);

  const symbol =
    value === undefined
      ? '\u2726'
      : value === 0
        ? '='
        : value > 0
          ? '\u2191'
          : '\u2193';

  const label =
    value === undefined
      ? styles.new
      : value === 0
        ? styles.same
        : value > 0
          ? styles.rise
          : styles.drop;

  let text = '';
  switch (label) {
    case styles.new: {
      text = 'Nova no top';
      break;
    }
    case styles.same: {
      text = 'Manteve a posição';
      break;
    }
    case styles.rise: {
      text =
        `Subiu ${absoluteValue.toString()} ` +
        (absoluteValue >= 2 ? 'posições' : 'posição');
      break;
    }
    case styles.drop: {
      text =
        `Caiu ${absoluteValue.toString()} ` +
        (absoluteValue >= 2 ? 'posições' : 'posição');
      break;
    }
  }

  return (
    <div classList={{ [styles.delta]: true, [label]: true }}>
      <span aria-hidden="true">
        {symbol}
        {value ? absoluteValue : null}
      </span>
      <span class={rootStyles['sr-only']}>{text}</span>
    </div>
  );
};

const TOP_SONGS = gql(`
  query TopSongs {
    requestedSongs(options: { limit: 5, sort: [{ rank: ASC }] }) {
      album
      artist
      title
      cover
      rank
      previousRank
    }
  }
`);

const getTopSongs = cache(async () => {
  'use server';

  const result = await client.query({ query: TOP_SONGS });

  return result.data.requestedSongs.map(song => ({
    ...song,
    cover: buildSources(song.cover),
  }));
}, 'topSongs');

export default function TopSongs() {
  const topSongs = createAsync(() => getTopSongs(), { initialValue: [] });
  const [controls, setControls] = createSignal(true);

  createEffect(() => {
    if (!isServer) {
      setControls(false);
    }
  });

  const states = () => ({
    playing: <IconTablerPlayerPauseFilled />,
    paused: <IconTablerPlayerPlayFilled />,
    buffering: <IconTablerLoader2 class={rootStyles.spin} />,
  });

  return (
    <section classList={{ [styles.wrapper]: true, [rootStyles.section]: true }}>
      <h2>
        Top Músicas Pedidas
        <span>
          <IconTablerHeadphonesFilled />
        </span>
      </h2>
      <Show
        when={topSongs().length > 0}
        fallback={<p>Ninguém pediu nada na semana passada...</p>}
      >
        <ol>
          <For each={topSongs()}>
            {(song, index) => {
              const [audioRef, setAudioRef] = createSignal<HTMLAudioElement>();
              const [state, setState] = createSignal<
                'playing' | 'paused' | 'buffering'
              >('paused');
              const [progress, setProgress] = createSignal(0);

              const togglePlay = () => {
                const audio = audioRef();
                if (!audio) {
                  return;
                }

                if (state() !== 'paused') {
                  audio.pause();
                  return;
                }

                for (const player of document.querySelectorAll<HTMLMediaElement>(
                  'audio, video',
                )) {
                  player.pause();
                }

                void audio.play();
              };

              return (
                <li
                  style={
                    state() === 'playing'
                      ? {
                          '--progress': `${(progress() * 100).toString()}%`,
                          '--remaining-progress': `${((1 - progress()) * 100).toString()}%`,
                        }
                      : {}
                  }
                >
                  <div class={styles.song}>
                    <div class={styles.inner}>
                      <Show when={!controls()}>
                        <button
                          type="button"
                          classList={{
                            [styles.playing]: state() === 'playing',
                          }}
                          onClick={togglePlay}
                        >
                          {states()[state()]}
                          <span class={rootStyles['sr-only']}>
                            {labels[state()]}
                          </span>
                        </button>
                      </Show>
                      <Image
                        displayFallback
                        class={styles.cover}
                        sources={song.cover}
                        alt=""
                      />
                      <div class={styles.metadata}>
                        <b>{song.title}</b>
                        <Show when={song.artist}>
                          <br />
                          {song.artist}
                        </Show>
                        <Show when={song.album}>
                          <br />
                          <cite>{song.album}</cite>
                        </Show>
                      </div>
                    </div>
                    <audio
                      ref={setAudioRef}
                      controls={controls()}
                      preload="none"
                      src={`${import.meta.env.VITE_STREAMING_API_ENDPOINT}/top-preview/${index().toString()}.mp3`}
                      onWaiting={() => {
                        setState('buffering');
                      }}
                      onPlaying={() => {
                        setState('playing');
                      }}
                      onPause={() => {
                        setState('paused');
                      }}
                      onTimeUpdate={event => {
                        setProgress(event.currentTarget.currentTime / 30);
                      }}
                    />
                  </div>
                  {formatDelta(
                    song.previousRank
                      ? song.rank - song.previousRank
                      : undefined,
                  )}
                </li>
              );
            }}
          </For>
        </ol>
      </Show>
    </section>
  );
}
